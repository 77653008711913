.extraloginBar{
    background-color: #694E4E;
    width: 2rem;
    height: 36rem;
    content: "\200B";
    border-radius: .3rem;
}

.loginContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5rem;
    justify-content: center;
    background-color: #A6CF98;
    width: 80rem;
    margin-left: 15%;
    border-radius: 1rem;
}


.loginFormContainer{
    height: 42rem;
    width: 25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 5rem;
    padding-left: 3rem;
}

/* .loginFormContainer form {
    height: 50%;
} */

.loginLogo img{
    width: 4.8rem;
}

.loginLogo{
    background-color: #F1EFEF;
    border-radius: 3rem;
    padding: .5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;  
}

.loginWelcome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: .3rem;
}
/* 
.loginWelcome h1{
    margin-left: 0;
} */


.loginWelcome span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.loginFormField{
    margin-top: 1.3rem;
}

.loginFormField label{
    display: flex;
    align-items: flex-start;
}

.loginFormField input{
    width: 18rem;
    height: 2.8rem;
}

.loginFormImage{
    background-color: #EA5455;
    width: 100%;
    border-radius: 0 1rem 1rem 0;
}

.loginFormImage img{
    margin: 5rem;
    width: 40rem;
    height: 35rem;
    border-radius: 1rem;
}

.loginForget{
    margin-top: 1.3rem;
}

.loginPassword{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginButton{
    margin-top: 2.5rem;
    width: 100%;
    height: 30%;
}
.loginButton button{
    width: 100%;
    height: 2.6rem;
    background-color: #694E4E;
    border-radius: .5rem;
    font-weight: bold;
}