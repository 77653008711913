

div.dropDown {
	font-family: Helvetica;
	font-size: 13px;
    position: absolute;
    width: 8.6%;
    height: 20%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    top: 6.5%;
    right: 0.1rem;
    z-index: 5;
}

div.dropDown {
	background-color: #444;
	background-image: -moz-linear-gradient(top, #444, #444);
	color: #ccc;
	padding: 10px;
	border-radius: 3px;
	box-shadow: 0px 0px 20px #999;
	margin: 25px;
	min-height: 50px;
	border: 1px solid #333;
	text-shadow: 0 0 1px #000;
}

.dropDown::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
}

.dropDown.bottom::before {
	right: 7px;
	top: -20px;
	border-bottom: 10px solid #444;
}

.dropDown hr {
	margin: 1%;
}

.dropDown p {
	margin: 8%;
}

.dropDownlinks{
	font-family: Helvetica;
	font-size: 0.97rem;
	text-decoration: none;
	color :white;
	padding: 2.6%;
}

.avtar{
	text-decoration: none;
}

