

.empSearch {
    width: 100%;
}

.empSearch p {
    color: red;
}

.create-container {
    display: flex;
    flex-direction: column;
    align-items: "flex-start";
    margin: 3.8rem;
    margin-top: 2.2rem;
}

.create-container hr{
    margin: 0;
}
.createInput{
    display: flex;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
}

.emp-inputBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.emp-inputBox label {
    display: flex;
}

.emp-inputBox p{
    font-size: medium;
    display: flex;
    align-items: flex-start;
    color: brown;
    position: fixed;
}

.emp-inputBox-submit{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 35rem;
    width: 100%;
}

.emp-inputBox-submit button {
    width: 8rem;
}

.empty-space {
    margin-top: 5rem;
}

.updateSubCategory-inputBox-submit{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}